import * as notifications from './actions';
import * as _ from "lodash";

export interface State {
  notifications: Array<any>;
  statistics: any;
  loading: number;
  scrollPosition: any;
}

export const initialState: State = {
  notifications: [],
  statistics: null,
  loading: 0,
  scrollPosition: 0,
};

export function reducer(state = initialState, action: notifications.Actions): State {

  switch (action.type) {
    case notifications.REQUEST_WATCHLIST_SUCCESS: {
      return {
        ...state,
        notifications: action.payload,
        loading: 0
      };
    }

    case notifications.SET_SCROLL_POSITION: {
      return {
        ...state,
        scrollPosition: action.payload
      };
    }

    case notifications.REQUEST_FILTERED_WATCHLIST: {
      if (action.payload.loadmore) {
        return {
          ...state,
          loading: 1
        };
      }
      return state;
    }

    case notifications.REQUEST_FILTERED_WATCHLIST_FINALIZE: {
      return {
        ...state,
        loading: 0
      };
    }

    case notifications.REQUEST_FILTERED_WATCHLIST_FAILURE: {
      return {
        ...state,
        loading: -1
      };
    }

    case notifications.REQUEST_FILTERED_WATCHLIST_SUCCESS: {
      const { filter, payload } = action;
      if (filter.loadmore) {
        if (payload) {
          return {
            ...state,
            loading: 0,
            notifications: [...state.notifications, ...payload]
          };
        } else {
          console.log('No more notifications to load');
          return {
            ...state,
            loading: -1
          };
        }
      } else if (filter.newsearch) {
        return {
          ...state,
          notifications: payload,
          loading: 0
        };
      } else {
        const timestamp = state.notifications.length > 0 ? state.notifications[0].timestamp : 0;
        const newNotifications = payload.filter(n => n.timestamp > timestamp);
        return {
          ...state,
          notifications: [...newNotifications, ...state.notifications]
        };
      }
    }

    case notifications.GET_WATCHLIST_STATISTICS_SUCCESS: {
      return {
        ...state,
        statistics: action.payload
      };
    }

    case notifications.EDIT_WATCHLIST_SUCCESS: {
      const { payload } = action;
      const unread = state.statistics ? Math.max(0, state.statistics.unread - (payload.read ? 1 : 0)) : 0;
      return {
        ...state,
        notifications: state.notifications.map(n => n.id === payload.id ? payload : n),
        statistics: state.statistics ? { ...state.statistics, unread } : null
      };
    }

    case notifications.READ_ALL_SUCCESS: {
      return {
        ...state,
        notifications: state.notifications.map(n => ({ ...n, read: true })),
        statistics: state.statistics ? { ...state.statistics, unread: 0 } : null
      };
    }

    case notifications.RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getState = (state: State) => state;
export const getNotifications = (state: State) => state.notifications;
export const getNotificationsStatistics = (state: State) => state.statistics;
export const isLoading = (state: State) => state.loading;
export const getScrollPosition = (state: State) => state.scrollPosition;
