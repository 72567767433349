import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import * as fromWatchlist from './watchlist';

export * from './effects';

export interface WatchlistState {
  watchlist: fromWatchlist.State;
}

export interface State extends fromRoot.State {
  watchlist: WatchlistState;
}
export const Reducers = {
  watchlist: fromWatchlist.reducer
};

export const selectTasksState = createFeatureSelector<WatchlistState>('watchlist');
export const selectTasksTasksState = createSelector(selectTasksState, (state: WatchlistState) => state.watchlist);
export const getState = createSelector(selectTasksTasksState, fromWatchlist.getState);
export const getNotifications = createSelector(selectTasksTasksState, fromWatchlist.getNotifications);
export const getStatistics = createSelector(selectTasksTasksState, fromWatchlist.getNotificationsStatistics);
export const isLoading = createSelector(selectTasksTasksState, fromWatchlist.isLoading);
export const getScrollPosition = createSelector(selectTasksTasksState, fromWatchlist.getScrollPosition);


