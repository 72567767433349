import { ModuleWithProviders }  from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ToastCustom } from './toast.custom';
import { HomeComponent } from './home.component';
import { HomeService } from './home.service';
import { HeaderComponent } from './blocks/header/header.component';
import { SidebarComponent } from './blocks/sidebar/sidebar.component';
import { VideoHeaderModalComponent } from './blocks/header/blocks/video/videoheadermodal.component';
import { NavigationComponent } from './blocks/navigation/navigation.component';
import { Submenu } from './blocks/navigation/blocks/submenu/submenu.component';
import { DevicesService } from './devices/devices.service';
import { MediaService } from './media/media.service';
import { DayService } from './day/day.service';
import { ProfileService } from './profile/profile.service';
import { LocationsService } from './locations/locations.service';
import { SitesService } from './sites/sites.service';
import { GroupsService } from './groups/groups.service';
import { RecentRecordingsService } from './recent-recordings/recent-recordings.service';
import { LivestreamService } from './livestream/livestream.service';
import { VideowallService } from './videowall/videowall.service';
import { CountingService } from './counting/counting.service';
import { SubscriptionService } from './subscription/subscription.service';
import { ChannelsService } from './channels/channels.service';
import { AlertsService } from './alerts/alerts.service';
import { AccountsService } from './accounts/accounts.service';
import { RolesService } from './roles/roles.service';
import { TasksService } from './tasks/tasks.service';
import { VaultService } from './vault/vault.service';
import { DashboardService } from './dashboard/dashboard.service';
import { LoggedInGuard } from '../guards/loggedin.guard';
import { OwnerGuard } from '../guards/owner.guard';
import { MqttService } from 'ngx-mqtt';
import { WatchlistService } from './watchlist/watchlist.service';
import { AdminGuard } from '../guards/admin.guard';
import { AnalyticsService } from './analytics/analytics.service';
import { IOService } from './io/io.service';

export const AllRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [LoggedInGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'events',
        loadChildren: () => import('./events/events.module').then(m => m.EventsModule),
      },
      {
        path: 'recordings',
        loadChildren: () => import('./recent-recordings/recent-recordings.module').then(m => m.RecentRecordingsModule),
      },
      {
        path: 'livestream',
        loadChildren: () => import('./livestream/livestream.module').then(m => m.LivestreamModule),
      },
      {
        path: 'videowall',
        loadChildren: () => import('./videowall/videowall.module').then(m => m.VideowallModule),
      },
      {
        path: 'media',
        loadChildren: () => import('./media/media.module').then(m => m.MediaModule),
      },
      {
        path: 'day',
        loadChildren: () => import('./day/day.module').then(m => m.DayModule),
      },
      {
        path: 'datasets',
        loadChildren: () => import('./datasets/datasets.module').then(m => m.DatasetsModule),
      },
      {
        path: 'counting',
        loadChildren: () => import('./counting/counting.module').then(m => m.CountingModule),
      },
      {
        path: 'devices',
        loadChildren: () => import('./devices/devices.module').then(m => m.DevicesModule),
      },
      {
        path: 'vault',
        loadChildren: () => import('./vault/vault.module').then(m => m.VaultModule),
      },
      {
        path: 'tasks',
        loadChildren: () => import('./tasks/tasks.module').then(m => m.TasksModule),
      },
      {
        path: 'watchlist',
        loadChildren: () => import('./watchlist/watchlist.module').then(m => m.WatchlistModule),
      },
      {
        path: 'sites',
        loadChildren: () => import('./sites/sites.module').then(m => m.SitesModule),
      },
      {
        path: 'groups',
        loadChildren: () => import('./groups/groups.module').then(m => m.GroupsModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'subscription',
        loadChildren: () => import('./subscription/subscription.module').then(m => m.SubscriptionModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'channels',
        loadChildren: () => import('./channels/channels.module').then(m => m.ChannelsModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'alerts',
        loadChildren: () => import('./alerts/alerts.module').then(m => m.AlertsModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'accounts',
        loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'roles',
        loadChildren: () => import('./roles/roles.module').then(m => m.RolesModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'logging',
        loadChildren: () => import('./logging/logging.module').then(m => m.LoggingModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'analytics',
        loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule),
      },
      {
        path: 'io',
        loadChildren: () => import('./io/io.module').then(m => m.AnalyticsModule),
      }
    ]
  },
];

export class HomeRoutes {

  public static getDeclarations() : any {
    return [
      HomeComponent,
      HeaderComponent,
      SidebarComponent,
      NavigationComponent,
      Submenu,
      VideoHeaderModalComponent,
      ToastCustom,
    ];
  }

  public static getEntryComponents() : any {
    return [
      VideoHeaderModalComponent,
      ToastCustom,
    ];
  }

  public static getProviders(): any {
    return [
      LoggedInGuard,
      OwnerGuard,
      AdminGuard,
      HomeService,
      DevicesService,
      MediaService,
      DayService,
      ProfileService,
      SitesService,
      SitesService,
      GroupsService,
      RecentRecordingsService,
      SubscriptionService,
      ChannelsService,
      LivestreamService,
      VideowallService,
      CountingService,
      AlertsService,
      LocationsService,
      VaultService,
      AccountsService,
      RolesService,
      TasksService,
      AnalyticsService,
      WatchlistService,
      DashboardService,
      IOService,
      { provide: 'mqttLegacy', useClass: MqttService },
      { provide: 'mqttNew', useClass: MqttService },
    ]
  }

  public static getAppRoutes() : ModuleWithProviders {
    return RouterModule.forRoot(AllRoutes);
  }
};
